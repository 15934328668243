<template>
    <div class="time-marks" v-bind:class="[type, {'label-on-mark': labelOnMark}]">
        <div class="mark" v-bind:class="{'day-split': DivisionCheck(indexM)}" v-bind:style="{'width': 'calc(100% /' + divisions + ')'}" v-for="(mark, indexM) in marks" :key="'mark' + indexM">
            <labelc v-if="mark.label !== undefined && mark.label !== null" :text="mark.label"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "timemarks",
        data(){
            return {
                divisions: 24,
                DisplayLabel: (index) => { return index },
                DivisionCheck: (index) => { return parseInt(index) == 0 },
                labelOnMark: false,
            }
        },
        computed: {
            marks(){
                let marks = [];
                for(let x = 0; x < this.divisions; x++){
                    let mark = {};
                    mark.label = this.DisplayLabel(x);
                    marks.push(mark);
                }
                return marks;
            },
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                }
            }
        },
        props:{
            state: Object,
            type: String,
        },
    }
</script>

<style scoped>
    .time-marks{
        pointer-events: none;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 10;
        padding: 0;
        white-space: nowrap;
        z-index: -1;
    }

    .time-marks .mark{
        text-align: center;
        position: relative;
        width: calc(100% / 24);
        height: calc(100% - 20px);
        margin-top: 20px;
        display: inline-block;
        box-shadow: inset 2px 0px 0 -1px  var(--contrast-1);
        background: none;
    }

    .time-marks .mark .label{
        position: absolute;
        top: -20px;
        left: 0;
        margin: 0;
        width: 100%;
        text-align: center;
    }

    .time-marks.label-on-mark .mark .label{
        left: -50%;
    }

    .time-marks .mark.day-split{
        box-shadow: inset 3px 0px 0 -1px  var(--contrast-4);
    }

    .time-marks.bottom-labels .mark.day-split{
        height: calc(100% - 20px);
    }


    .time-marks.bottom-labels .mark{
        margin-top: initial;
        margin-bottom: 20px;
        height: calc(100% - 30px);
    }


    .time-marks.bottom-labels .label{
        top: initial;
        bottom: -20px;
    }
</style>